<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <multi-input
          class="mt-4"
          v-model="model.files"
          :schema="filesSchema"
          title="فایل ها"
        />

        <v-row align="center" justify="end" class="my-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش دوره
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";
import MultiInput from "../../../../components/MultiInput.vue";
import moment from "jalali-moment";

export default {
  components: {
    VFormBase,
    MultiInput,
  },
  created() {
    this.getData();
    this.getInstructors();
    this.getCourses();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        user_id: "",
        title: "",
        slug: "",
        status: "",
        desc: "",
        price: "",
        offer_price: "",
        end_date_offer: "",
        requirements: [],
        duration: "",
        capacity: "",
        level: "",
        video_id: null,
        media_id: null,
        link: "",
        files: [],
        demo_url: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "نام دوره",
          rules: [Constants.rules.required],
          required: true,
        },
        slug: {
          type: "CustomInput",
          label: "اسلاگ",
          rules: [Constants.rules.required],
          required: true,
        },
        user_id: {
          type: "CustomInput",
          label: "دوره برای کاربر",
          inputType: "autocomplete",
          items: [],
          rules: [Constants.rules.required],
          itemValue: "id",
          itemText: "value",
          loading: true,
        },
        status: {
          type: "CustomInput",
          label: "وضعیت",
          inputType: "select",
          items: [
            { id: 0, value: "در حال بررسی" },
            { id: 1, value: "تایید شده" },
            { id: 2, value: "رد شده" },
          ],
          itemValue: "id",
          itemText: "value",
          rules: [Constants.rules.required],
          required: true,
        },
        desc: {
          type: "MyTinyMCE",
          label: "توضیحات",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        price: {
          type: "CustomInput",
          label: "قیمت",
          rules: [Constants.rules.required],
          required: true,
          suffix: "تومان",
        },
        offer_price: {
          type: "CustomInput",
          label: "قیمت با تخفیف",
          required: true,
          suffix: "تومان",
        },
        end_date_offer: {
          type: "ModalDatePicker",
          label: "تاریخ پایان",
          min: moment(new Date()).format("YYYY/MM/DD"),
          required: true,
        },
        duration: {
          type: "CustomInput",
          label: "مدت",
          rules: [Constants.rules.required],
          required: true,
          suffix: "ساعت",
        },
        capacity: {
          type: "CustomInput",
          label: "گنجایش",
          rules: [Constants.rules.required],
          required: true,
          suffix: "نفر",
        },
        level: {
          type: "CustomInput",
          inputType: "select",
          items: [
            { id: 1, value: "مقدماتی" },
            { id: 2, value: "پیشرفته" },
            { id: 3, value: "حرفه ای" },
          ],
          itemValue: "id",
          itemText: "value",
          label: "سطح",
          rules: [Constants.rules.required],
          required: true,
        },
        requirements: {
          type: "CustomInput",
          label: "پیش نیاز ها",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "title",
          multiple: true,
          loading: true,
          col: { cols: 12 },
        },
        media_id: {
          type: "MyFilePond",
          title: "تصویر پیشفرض دوره",
          required: true,
          col: { cols: 12 },
        },
        video_id: {
          type: "MyFilePond",
          title: "دمو دوره",
          fileTypes: "video/mp4",
          maxFileSize: "300MB",
          col: { cols: 12 },
        },
        demo_url: {
          type: "CustomInput",
          label: "لینک دمو دوره",
          class: "ltr",
          col: { cols: 12 },
        },
      },
      filesSchema: {
        media_id: {
          type: "MyFilePond",
          rules: [Constants.rules.required],
          required: true,
          fileTypes: null,
          col: { cols: 12 },
        },
        title: {
          type: "CustomInput",
          label: "عنوان",
          required: true,
          col: { cols: 5 },
        },
        link: {
          type: "CustomInput",
          label: "لینک فایل",
          class: "ltr",
          col: { cols: 7 },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/courses/index/" + this.id, {
        params: {
          noPaginate: true,
          with: "pre_requirements",
        },
      })
        .then((response) => {
          console.log(response.data, typeof response.data[0].media);
          this.title = response.data[0].title;
          formGetPrepare(response, this.model);

          this.model.requirements = response.data[0].pre_requirements
            ? response.data[0].pre_requirements.map(
                (val) => val.pre_requirement_id
              )
            : [];

          this.schema.video_id.files = response.data[0].video || {};

          this.schema.media_id.files = response.data[0].media || {};

          let files = JSON.parse(response.data[0].files || "[]");

          console.log("files", files);

          this.model.files = response.data[0].files_media
            ? response.data[0].files_media
                .filter((val, i) => {
                  if (val) return true;
                  else {
                    files.splice(i, 1);
                    return false;
                  }
                })
                .map((val, i) =>
                  val.id
                    ? { media_id: val, title: files[i] ? files[i].title : "" }
                    : { link: val, title: files[i] ? files[i].title : "" }
                )
            : [];

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getInstructors() {
      MyAxios.get("/user/list/instructor", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: [
              {
                id: 1,
                value: "ادمین",
              },
              ...response.data.map((val) => ({
                id: val.id,
                value: val.first_name + " " + val.last_name,
              })),
            ],
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCourses() {
      this.schema.requirements.loading = true;
      MyAxios.get("/courses/index", {
        params: {
          noPaginate: true,
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          this.schema.requirements.items = response.data?.filter(
            (val) => val.slug != this.id
          );
          this.schema.requirements.loading = false;
        })
        .catch((error) => {
          console.log("laws", error.response);
          this.schema.requirements.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        let files = this.model.files.filter(
          (val) => val?.media_id || val?.link?.length
        );
        // if (!this.model.video_id) {
        //   this.$root.$emit("toast", {
        //     text: "بارگذاری ویدئو اجباری است.",
        //   });
        //   return;
        // }
        this.loading = true;
        let data = formPostPrepare(this.model);
        // data.append("slug", this.model.title.replace(/\/|\s|\./g, "-"));
        data.append("slug", this.model.slug.replace(/\/|\s|\./g, "-"));
        data.append("instructor_id", this.model.user_id);

        data.append(
          "requirements",
          JSON.stringify(
            this.model.requirements.map((val) => ({
              pre_requirement_id: val,
            }))
          )
        );

        if (this.model.video_id)
          data.append(
            "video_id",
            this.model.video_id?.id || this.model.video_id
          );

        if (this.model.media_id)
          data.append(
            "media_id",
            this.model.media_id?.id || this.model.media_id
          );

        if (files.length > 0)
          data.append(
            "files",
            JSON.stringify(
              files.map((val) => {
                let is_external =
                  typeof val.link == "string" && val?.link?.length;
                return {
                  is_external: !!is_external || undefined,
                  media_id: is_external
                    ? undefined
                    : val.media_id?.id || val.media_id,
                  link: is_external ? val.link : undefined,
                  title: val.title || "",
                };
              })
            )
          );
        else data.append("files", "[]");

        MyAxios.post(`courses/${this.id}/update`, data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "دوره با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else if (parseInt(error.response.status) === 421) {
              this.$root.$emit("toast", {
                text: "اسلاگ وارد شده تکراری است. لطفا نام دوره را تغییر دهید.",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
